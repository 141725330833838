<template>
    <div class="big">
        <div class="login-form">
            <div class="login-logo">
                <!-- <img src="../../assets/images/system/login_logo.png"> -->
                <div>华西集团-易方汇智对账系统</div>
            </div>
            <div class="form-body">
                <!-- <el-form :model="loginForm" status-icon :rules="loginRules" ref="loginForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-input v-model="loginForm.username" placeholder="账号" prefix-icon="el-icon-user"></el-input>
                    <el-input v-model="loginForm.password" placeholder="密码" prefix-icon="el-icon-lock" :type="show.type"
                              style="cursor: pointer">
                        <i class="iconfont"
                           slot="suffix"
                           @click="handleIconClick">{{show.display == true?'&#xe61d;':'&#xe61e;'}}
                        </i>
                    </el-input>
                </el-form> -->
                <div class="input-box">
                    <input type="text" placeholder="用户名" v-model="loginForm.username">
                </div>
                <div class="input-box">
                    <input :type="flag?'password':'text'" placeholder="密码" v-model="loginForm.password">
                    <i class="iconfont" @click="flag=!flag">{{flag?'&#xe61d;':'&#xe61e;'}}</i>
                </div>
            </div>
            <button @click="login('loginForm')" :disabled="isDisabled" >{{ this.isDisabled == true?'登录中...':'登 录'}}</button>
        </div>
        <div class="footer-show">
            <!-- 易方汇智科技有限公司 版权所有©2021 客服热线：400-8521-747
            <a style="color: #aaa" href="https://beian.miit.gov.cn/#/Integrated/index"> 蜀ICP备20006618号-2</a> -->
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isDisabled: false,
                flag: true,
                inputType: 'password',
                show: {
                    type: 'password',
                    display: false
                },
                loginForm: {
                    username: '',
                    password: '',
                    grant_type: 'password',
                    client_id: 'web_admin',
                    client_secret: 'web_admin'
                },
                loginRules: {
                    username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                },
            }
        },
        methods: {
            login() {
                this.isDisabled = true
                if (this.loginForm.username == '') {
                    this.$message.error("账号不能为空");
                    this.isDisabled = false
                    return;
                } else if (this.loginForm.password == '') {
                    this.$message.error("密码不能为空");
                    this.isDisabled = false
                    return;
                }
                this.$api.login(this.loginForm)
                    .then(response => {
                        if (response.access_token) {
                            localStorage.setItem("accessToken", response.access_token)
                            localStorage.setItem("refreshToken", response.refresh_token)
                            this.$api.getMenu().then(res => {
                                var username = res.data.userInfo.userName;
                                localStorage.setItem("username", username)
                                localStorage.setItem("roleName", res.data.userInfo.roles[0].roleCode)
                                localStorage.setItem("isSalesman", res.data.userInfo.roles[0].roleCode == 'salesman')
                                if (res.data.userInfo.roles[0].roleCode == 'admin'
                                    || res.data.userInfo.roles[0].roleCode == 'manager'
                                    || res.data.userInfo.roles[0].roleCode == 'finance_supervisor'
                                    || res.data.userInfo.roles[0].roleCode == 'production_supervisor'
                                    || res.data.userInfo.roles[0].roleCode == 'product_supervisor'
                                    || res.data.userInfo.roles[0].roleCode == 'operations_supervisor'){
                                    this.$router.push('/bossIndex')
                                    this.isDisabled = false
                                }else if(
                                    res.data.userInfo.roles[0].roleCode == 'hx_self'
                                    || res.data.userInfo.roles[0].roleCode == 'hx_jc'
                                ){
                                    this.$router.push('/HxIndex')
                                }else {
                                    this.$router.push('/index')
                                    this.isDisabled = false
                                }
                            })
                        } else {
                            this.$message.error("输入的密码和账号不匹配");
                            this.isDisabled = false
                        }
                    }).catch(() => {this.isDisabled = false})
            },
            handleIconClick() {
                if (this.show.display == false) {
                    this.show.type = "text";
                    this.show.display = true;
                } else {
                    this.show.type = "password";
                    this.show.display = false;
                }
            },
        },
        mounted() {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("username");
            localStorage.removeItem("menuTree");
            localStorage.removeItem("authorityList");
            localStorage.removeItem("isSalesman");
            
            this.$nextTick(() => {
                document.onkeypress = (e) => {
                    var e = e || window.event
                    if (e.keyCode == 13) {
                        if(this.isDisabled){
                            return;
                        }
                        this.login();
                    }
                }
            })
        },
        beforeDestroy() {
            document.onkeypress = null
        }
    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 14px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 5px;
        margin-top: 47%;
        display: flex;
        color: #808080;
        position: absolute;
        right: 0;
        top: -170px;
    }

    .login-logo {
        margin-bottom: 40px;
        height: 100px;
        font-size: 32px;
        font-weight: bold;
    }

    .input-text {
        border: 1px red solid;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        border: none !important;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        width: 100%;
    }

    .form-body {
        height: 40%;
    }

    .big {
        height: 100%;
        width: 100%;
        background: url('../../assets/images/system/login_back.png') no-repeat center center;
        background-size: 100% 100%;
    }

    .login-form {
        width: 600px;
        height: 400px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 20px;
        position: relative;
        text-align: center;
        left: 70%;
        top: 48%;
        transform: translate(-50%, -50%);
    }

    .login-title {
        margin-top: 32px;
        margin-bottom: 15px;
        width: 219px;
        height: 48px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        float: right;
        width: 100%;
        font-weight: 400;
        color: #fff;
    }

    .title-text {
        color: #242f42;
    }

    .chinese {
        font-family: 黑体;
        font-size: 32px;
    }

    .English {
        margin-bottom: 40px;
    }

    .el-input {
        width: 80%;
        margin: 0 0 40px 0;
    }

    button {
        width: 67%;
        height: 50px;
        background-color: #437EE6;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
    }

    button:active {
        background-color: rgba($color: #437EE6, $alpha: 0.9);
    }

    .footer-show {
        position: fixed;
        bottom: 2%;
        width: 100%;
        text-align: center;
        height: 16px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #aaa;
        letter-spacing: 1px;
    }

    .el-input__inner {
        border: none;
        border-bottom: 1px solid #DCDFE6;
    }

    .input-box {
        width: 67%;
        border-bottom: 1px solid rgba($color: #ccc, $alpha: 0.7);
        margin: 20px auto;
        margin-top: 10px;
        height: 45px;
        padding: 5px;
        box-sizing: border-box;
        position: relative !important;

        input {
            color: #999999;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-size: 15px;
        }

        input::active {
            background-color: rgba($color: #437EE6, $alpha: 0.8);
        }

        input::placeholder {
            color: #999999;
        }

        input:-webkit-autofill {
            -webkit-text-fill-color: #999999 !important; /*记住密码的字的颜色*/
            transition: background-color 5000s ease-in-out 0s; /*延时渲染背景色来去除背景色*/
            caret-color: #999999; /*光标颜色*/
        }
    }
</style>
